import React from "react";
import "./_badge.scss";
import {connect} from "react-redux";
import {getBadgeColour, getUnreadCounter} from "../../selectors/notificationSelector";

/**
 * ALARM - PRZYNAJMNIEJ JEDEN NIEODCZYTANY ALARM
 * WARNING- NIE MA ALARMÓW ALE JEST NIEODCZYTANE OSTRZEŻENIE
 * INFORMATION - NIE MA ŻADNYCH ALARMÓW I OSTRZEŻEŃ ALE JEDNO POWIADOMIENIE INFORMACYJNE
 */


export class Badge extends React.Component {

    render() {
        const {notificationCounter, show, notificationColor} = this.props;
        if (!show || !notificationCounter) {
            return null;
        }
        return (
            <div className={`badge ${notificationColor}`}>
                <i>{notificationCounter > 99 ? "+99" : notificationCounter}</i>
            </div>
        )
    }
}

Badge = connect(state => ({
    notificationCounter: getUnreadCounter(state),
    notificationColor: getBadgeColour(state),
    notifications: state.notificationCenter.notifications,
    localUserID: state.user.user.LocalUserID
}))(Badge);

export default Badge;
